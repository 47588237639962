.player-wrapper {
  width: 100%;
  height: 76vh;
  text-align: center;
  //background: "rgba(56, 67, 79, 0.8)";
  //filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2))";
}

.dollhouse-wrapper {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.react-player {
  /* position: absolute;
    top: 0;
    left: 0; */
  text-align: center;
}
