.progress-checklist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 5px;

  .label {
    font-size: 16px;
    margin-left: 10px;
  }

  a {
    color: #777;
  }
}
