$appColor1: #38434f;
$appColor2: #545d66;
$appBackground1: #a9adb2;
$appBackground2: rgba(56, 67, 79, 0.8);

.clientInfo {
  padding: 10px 20px;
  color: white;
  font-weight: 400;
  font-size: 15px;
  display: flex;
}

.titleStreetName {
  padding: 20px 10px 0px 20px;
  color: white;
  font-weight: 300;
  font-size: 30px;
}

.cityState {
  padding: 0px 20px 0px 0px;
  color: white;
  font-size: 15px;
  font-weight: 300;
}

.client {
  position: relative;
  top: 15px;
}
.clientName {
  text-transform: uppercase;
  font-weight: 600;
}
.brokerage {
  position: relative;
  left: 64px;
  bottom: 16px;
}
.ant-tabs.media {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(4, 4, 4, 0.65);
  font-size: 16px;
  /* background-color: #545d66; */
  /* background: url(https://homesnappers-comms.s3.us-east-2.amazonaws.com/shoots/photos/high/6000193_4105wayfaringst_25207summerchasedr-08.jpg); */
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  display: "-ms-flexbox";
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.ant-tabs.media > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs.media > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px;
  -webkit-transform: translate(0);
  transform: translate(0);
}
.ant-tabs.media > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs.media > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  color: $appColor2;
}

.ant-tabs-card.media > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.media > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background-image: linear-gradient($appBackground1, white);
  // background: $appBackground1;
  color: $appColor1;
  border: none !important;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-content-holder {
  -ms-flex: auto;
  flex: auto;
  width: 100%;
}

.ant-tabs-tab-active {
  background-image: linear-gradient(white, white) !important;
  font-weight: 500;
}

.ant-tabs-card.media.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 10px 26px 10px;
}

.ant-tabs-card.media.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.media.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0px 0px 0 0;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  content: "";
}

.bg2 {
  position: absolute;
  /* width: 1440px;
  height: 1024px; */
  left: 0px;
  top: 0px;
  width: 100%;
  background: $appBackground2;
  box-shadow: 0px 4px 30px rgba(58, 55, 74, 0.1);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
}

.bg1 {
  // position: absolute;
  width: 100%;
  height: 100vh;
  /* left: -170px; */
  top: 0px;
  background: #58616a;
  // filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
  // background-repeat: no-repeat;
  // background-size: cover;
  /* filter: blur(8px); */
}

.insta:hover {
  color: #df5885 !important;
}

.fb:hover {
  color: #4e85f5 !important;
}

.tweet:hover {
  color: #4fb1ee !important;
}

.footer {
  height: 4vh;
  // position: absolute;
  width: 100%;
  top: 100vh;
  margin-bottom: 0;
  padding: 5px;
  background: #58616a;
  // background: $appBackground2;
  // filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
}

.contactDetail {
  margin-right: 15px;
}

@media only screen and (max-width: 768px) {
  .clientInfo {
    padding: 0 20px;
  }

  .titleStreetName {
    padding: 20px 10px 0px 20px;
    font-size: 22px;
  }

  .contactDetail {
    display: block;
    width: 100%;
  }

  .social-icons {
    display: none;
  }
}
