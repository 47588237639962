@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@600;700&family=Lato&display=swap");

.fl-wrap {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: #242424;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
}

.fl-wrap * {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 150px;
}

.fl-wrap *::before,
.fl-wrap *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.fl-wrap :focus,
.fl-wrap :hover,
.fl-wrap :active,
.fl-wrap :visited {
  outline: none;
}

.fl-wrap b,
.fl-wrap strong {
  font-weight: 700;
}

.fl-wrap ul,
.fl-wrap ol {
  list-style: none;
}

.fl-wrap img {
  display: block;
  max-width: 100%;
  height: auto;
}

.fl-wrap address {
  font-style: normal;
  font-size: 14px;
  min-width: 300px;
}

.fl-container {
  position: relative;
  max-width: 250px;
}

.fl-col-bg {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  width: 200px;
  background: linear-gradient(
    180deg,
    #f7dbe5 0%,
    rgba(243, 232, 236, 0.6) 100%
  );
  opacity: 0.95;
  z-index: 2;
}

.fl-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.23;
}

.fl-logo {
  width: 150px;
}

.fl-logo img {
  width: 100%;
}

.fl-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 25px;
  line-height: 1.58;
  color: #5b5b5b;
}

.fl-location__icon {
  width: 15px;
  min-width: 15px;
  margin-right: 14px;
}

.fl-location__icon img {
  width: 100%;
}

.fl-list {
  font-size: 12px;
  line-height: 1.2;
  max-width: 150px;
}

.fl-list li {
  position: relative;
  padding: 0px 0px 12px 20px;
}

.fl-list li:last-child {
  margin-bottom: 0;
}

.fl-list li:before {
  position: absolute;
  content: "";
  /* top: 13px; */
  left: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #242424;
}

.fl-main {
  position: relative;
  width: 100%;
}

.fl-main__img {
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 12px;
  overflow: hidden;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  width: 770px;
}

.fl-main__content {
  position: relative;
  width: 150px;
  margin-left: 25px;
  padding: 30px 25px 50px;
  z-index: 3;
}

.fl-main__content .fl-title {
  max-width: 190px;
  font-size: 15px;
  text-transform: uppercase;
  padding-bottom: 15px;
}

.fl-main__content .fl-location {
  padding: 10px 0px 20px 0px;
}

.fl-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fl-desc__left {
  position: relative;
  width: 250px;
  min-width: 250px;
  padding: 40px 25px;
  margin-left: 25px;
  z-index: 3;
}

.fl-desc__left .fl-small-img {
  margin-bottom: 40px;
}

.fl-desc__left .fl-small-img:last-child {
  margin-bottom: 0;
}

.fl-street {
  padding: 10px 0px;
  font-size: 15px !important;
  font-weight: 700;
  font-style: normal;
}

.fl-headline {
  font-size: 14px;
  padding-bottom: 10px;
  font-style: normal;
  font-variant-caps: all-small-caps;
}
.fl-desc__right {
  width: calc(100% - 515px);
  padding-top: 40px;
}

.fl-person-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fl-person-block .fl-photo {
  min-width: 150px;
  height: 150px;
  margin-right: 40px;
  overflow: hidden;
}

/* .fl-info {
  padding-right: 40px;
} */

.fl-info__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 1.52;
  margin-bottom: 10px;
}

.fl-info__item:last-child {
  margin-bottom: 0;
}

.fl-info__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 17px;
  min-width: 17px;
  margin-right: 10px;
}

.fl-info .fl-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.fl-info .fl-text {
  color: #5b5b5b;
  margin-bottom: 25px;
  font-size: 12px;
}

.fl-text-block .fl-title {
  margin: 10px 0;
}

.fl-text-block .fl-text {
  color: #5b5b5b;
  width: 450px;
  font-size: 14px;
  margin-top: 10px;
}
