.login-container {
  width: 100%;
  margin-top: -100px !important;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // background-image: url("http://www.homesnappers.com/wp-content/uploads/2017/02/portfolio-6.jpg");
  // background-image: url("http://www.homesnappers.com/wp-content/uploads/2017/02/portfolio-3.jpg");
  background-image: url("https://homesnappers-comms.s3.us-east-2.amazonaws.com/public/portfolio/portfolio23_1920.webp");
}
.invitation-container {
  width: 100%;
  margin-top: -100px !important;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../../webapp/content/icons/HS-invitation.jpg");
}
.login-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  width: 450px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 15%;

  .logo {
    width: 400px;
    margin-bottom: 50px;
    margin-right: 0;
  }

  a {
    color: #50a9fd !important;
  }

  a:hover {
    color: #2f9afd !important;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
}
