.equipment-log {
  .img-thumbnail {
    width: 80px;
    height: auto;
    border-radius: 5px;
  }
}

.device-detail {
  .description-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .description {
    margin: 20px;
  }
  .title {
    margin-left: 20px;
  }
  .device-notes {
    padding: 20px;
  }
}

.device-form {
  .ant-upload {
    border: dashed 1px #d9d9d9;
  }
}
