:root {
  --main-bg-color: #fff;
  --link-color: #1079c6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-layout-content {
  /* background: var(--main-bg-color); */
  margin-top: 25px;
}

.ant-card-body {
  padding: 10px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  background: none;
}

.ant-table-thead > tr > th {
  background: #f8f9fa !important;
  font-weight: bold !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #f8f9fa !important;
  font-weight: bold;
}

.ant-table-column-sorter {
  color: #1079c6 !important;
}

.ant-table-filter-trigger {
  color: #1079c6 !important;
}

@media only screen and (max-width: 768px) {
  .ant-layout-content {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ant-layout-content {
    margin-top: 0;
  }
}
