.loading-container {
  text-align: center;
  // background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  // margin-bottom: 20px;
  padding: 30px 50px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  height: 2em;
  width: 2em;
}

.loading-container:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(230, 240, 250, 0.5),
    rgba(218, 218, 218, 0.5)
  );

  background: -webkit-radial-gradient(
    rgba(230, 240, 250, 0.5),
    rgba(218, 218, 218, 0.5)
  );
}
