.invoice-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}

.ant-checkbox {
  border: solid 1px #888 !important;
}

.invoice-card {
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  background-color: #ffffff;
}

.invoice-logo {
  flex: 0 0 auto;
  margin-bottom: 10px;
}

.invoice-item {
  flex: 0 0 auto;
  text-align: center;
  color: #838383;
}

.invoice-line-item {
  padding: 7px 0;
  border-top: solid 1px #cfcfcf;
}

.invoice-line-total {
  display: block;
  padding: 7px 0;
  border-top: solid 3px #cfcfcf;
}

.pay-icon {
  width: 30px;
  margin-right: 5px;
}

.pay-button {
  color: #fff;
  background: #1f9102;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  min-width: 80px;
  border: none;
  padding: 3px;
  font-size: 1rem;
  border-radius: 3px;
  margin-right: 10px;
}

button[disabled="disabled"],
button:disabled {
  color: #7aa789;
  background: #b2dfa6;
  text-shadow: none;
  min-width: 80px;
  border: none;
  padding: 3px;
  font-size: 1rem;
  border-radius: 3px;
  margin-right: 10px;
}

.payment-container img {
  width: 30px;
}

input[type="text"] {
  font-size: 16px !important;
  font-family: sans-serif;
  color: #424770;
  font-weight: 500 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adbac6 !important;
  font-size: 16px;
  font-family: sans-serif;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #adbac6 !important;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.2em;
  font-weight: 500;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #adbac6 !important;
  font-size: 16px;
  font-family: sans-serif;
}
