@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");

$poppins: "Poppins", sans-serif;
$oswald: "Oswald", sans-serif;
$mainBg: #f7f7f7;
// $mainBg: #f3f7ff;
$primary: #f54b64;
$primaryAccent: #ff6b81;
$primaryHover: #d83850;
$primaryText: #1e1e1e;
$secondary: linear-gradient(95.72deg, #4e596f 0.6%, #242a38 96.54%);
$secondaryAccent: #4e596f;
$secondaryHover: #161b25;
$secondaryText: #ffffff;
$primaryForeground: #ffffff;
$primaryBackground: linear-gradient(95.72deg, #4e596f 0.6%, #242a38 96.54%);
$primaryBackgroundHover: #0968ac;
$secondaryBackground: #1d5eb3;
$primaryBtn_website: #1079c6;
$darkGrey: #333333;
$lightGrey: #acabab;
$white: #f5f5f5;
$titleBlack: #191919;
$padding: 20px;
$margin: 100px;
