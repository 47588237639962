.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
// .ant-card-cover > * {
//   display: block;
//   width: 125px !important;
//   height: 125px !important;
// }
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  // top: 50px;
  font-size: 15px;
}

.ant-checkbox {
  border: solid 0px #04a4e8;
}

.col-align {
  padding: 1em;
}
.row-align {
  margin-left: 2em;
}
