@import "../variable";
.ant-menu-horizontal.user > .ant-menu-item:hover,
.ant-menu-horizontal.user > .ant-menu-submenu:hover,
.ant-menu-horizontal.user > .ant-menu-item-active,
.ant-menu-horizontal.user > .ant-menu-submenu-active,
.ant-menu-horizontal.user > .ant-menu-item-open,
.ant-menu-horizontal.user > .ant-menu-submenu-open,
.ant-menu-horizontal.user > .ant-menu-item-selected,
.ant-menu-horizontal.user > .ant-menu-submenu-selected {
  border-bottom: 1px solid #f7f7f7;
}
.ant-menu.user {
  background: #fff;
  border-bottom: none;
  line-height: 40px;
  position: relative;
  top: 11px;
  text-align: left;
  // text-transform: uppercase;
}
.nav:hover {
  color: $lightGrey !important;
}

.ant-menu-horizontal {
  text-transform: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: $primaryText;
}

// .ant-menu-horizontal > .ant-menu-item-selected a::after {
//   content: "";
//   position: absolute;
//   left: 57.44%;
//   right: 42.2%;
//   top: 77.78%;
//   bottom: 11.11%;
//   background: #f54b64;
//   width: 5px;
//   height: 5px;
//   border-radius: 50%;
// }

.primaryBtn {
  color: $primaryForeground;
  background-color: $primary;
  border-color: $primary;
  background: linear-gradient(94.43deg, #f54b64 0%, #ff6b81 100%);
  border-radius: 6px;
}

.primaryBtn a {
  color: #ffffff !important;
}

.primaryBtn:hover {
  color: $primaryForeground !important;
  background-color: $primaryBackgroundHover !important;
}

.content-wrapper {
  padding: $padding;
}

h4 {
  margin: 10px 0;
}

.ant-modal {
  border-radius: 12px !important;
}

.ant-modal-header {
  background: linear-gradient(
    95.72deg,
    #4e596f 0.6%,
    #242a38 96.54%
  ) !important;

  .ant-typography {
    color: #ffffff !important;
    margin: 0;
  }
}

// .ant-modal-close-x {
//   color: #ffffff !important;
// }

.banner-desc {
  color: #ffffff;
  text-shadow: 2px 2px 2px #333333;
  max-width: 700px;
  font-size: 18px;
  margin: 10px auto;
  padding: 10px;
}

// .ant-btn-primary:hover {
//   color: $primaryForeground !important;
//   background-color: $primaryBackgroundHover !important;
// }

.feat-item-title {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 100;

  strong {
    font-weight: bold;
  }
}
