.client-tabs {
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
}

.client-notes {
  p {
    padding-bottom: 0 !important;
  }
}

.client-name {
  font-size: 18px;
  font-weight: 600;
  color: #112350;
}

.shoot-summary h4 {
  color: #112350;
  font-size: 16px;
  font-weight: 700;
}

.shoot-summary-item {
  color: #112350;
  padding: 5px 0;
  border-top: solid 1px #fff;
}
