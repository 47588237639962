@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,200,100,400italic,700,900|Muli&subset=latin,vietnamese,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;1,300&display=swap");
@import "~antd/dist/antd.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/daygrid/main.css";
/* @import "~react-image-gallery/styles/scss/image-gallery.scss"; */
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "./variable";
/* .site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
} */

body {
  background: #fff !important;
  height: 100%;
  font-family: "Roboto", sans-serif !important;
  color: #272727 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  color: #3a3a3a !important;
}

.logo {
  width: 180px;
  // height: 25px;
  /*margin: 16px 24px 16px 0;*/
  float: left;
  margin-right: 80px;

  img {
    width: 100%;
  }
}

.timestamp {
  color: #929292;
  font-style: italic;
  font-size: 0.8rem;
  display: block;
}

.note-container {
  margin: 5px 0;
  padding: 5px;
  border-top: 1px solid #f0f0f0;
}

.ant-layout-header {
  height: 66px !important;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 62px;
  background: #323333 !important;
  border-bottom: solid 1px #eee;
}

.ant-table {
  color: #323333 !important;
  font-size: 0.85rem !important;
}

.ant-tabs {
  color: #323333 !important;
}

.ant-table-sticky-header {
  position: sticky;
  z-index: -1 !important;
}

.ant-btn {
  border-radius: 4px !important;
  font-family: "Poppins", sans-serif !important;
  // color: $secondaryText !important;
  // background-color: $secondary !important;
  // border-color: $secondaryAccent !important;
}

.ant-btn-primary {
  background: $primary !important;
  border-color: $primaryAccent !important;
}

.ant-btn-primary:hover {
  color: $white !important;
  background-color: $primaryHover !important;
  border-color: $primary !important;
}

.ant-btn-primary[disabled] {
  color: rgba(142, 142, 142, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-btn-secondary {
  color: $secondaryText !important;
  background: $secondary !important;
  border-color: $secondaryAccent !important;
}

.ant-btn-secondary:hover {
  color: $secondaryText !important;
  background-color: $secondaryHover !important;
  border-color: $secondary !important;
}
.ant-btn-secondary[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-layout {
  background: #f3f7ff !important;
}

.ant-modal-close {
  color: #9e9e9e !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.appHeader {
  background: #ffffff !important;
  position: fixed;
  z-index: 3;
  width: 100%;
  // text-transform: uppercase;
}

.appFooter {
  background: #333333 !important;
  color: #ffffff !important;
  /* padding: unset !important; */
  padding: 10px;
  position: absolute;
  bottom: 0;
}

.appBackground {
  background: #f0f2f4 !important;
  color: #102250 !important;
}

.appButton {
  color: #102250 !important;
  padding: 45px;
  border: 1px solid #798ec1;
}

.appButton:hover {
  border: 1px solid #102250;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
  background: #141414;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mediaBgImg {
  position: relative;
  z-index: 1;
  opacity: 1;
}
.tourTheme {
  position: relative;
  z-index: 2;
  background: #545d66;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: none !important;
  content: "";
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
  border-bottom: 3px solid $primary !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: $primaryText !important;
  border-bottom: 2px solid $primary !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none !important;
}

.notification {
  background-color: #ffffff;
  padding: 5px 30px 5px 5px;
  margin-top: 2px;
  right: 0;
  float: right;
  border-bottom: solid 1px #f0f0f0;
  border-left: solid 1px #f0f0f0;
  border-top: solid 1px #f0f0f0;
  border-radius: 5px 0 0 5px;
}

table a {
  color: #1890ff;
}

// a {
//   color: #50a9fd !important;
// }

// a:hover {
//   color: #2f9afd !important;
// }

// .book-button {
//   color: #ffffff !important;

.ant-menu-horizontal.user > .ant-menu-item:hover,
.ant-menu-horizontal.user > .ant-menu-submenu:hover,
.ant-menu-horizontal.user > .ant-menu-item-active,
.ant-menu-horizontal.user > .ant-menu-submenu-active,
.ant-menu-horizontal.user > .ant-menu-item-open,
.ant-menu-horizontal.user > .ant-menu-submenu-open,
.ant-menu-horizontal.user > .ant-menu-item-selected,
.ant-menu-horizontal.user > .ant-menu-submenu-selected {
  border-bottom: solid 2px $primary !important;
  // color: white;
}

.ant-menu-horizontal > .ant-menu-item a {
  // color: #fff;
}
// }

.menu-wrapper {
  display: flex;
  justify-content: space-between;

  .logo img {
    width: 220px;
  }

  .nav-items {
    // align-items: center;
    // width: 100%;
  }

  .actions {
    margin-left: auto;
  }

  .hamburger {
    display: inline;
      color: #ffffff;
      margin-left: auto;
  }

  @media only screen and (max-width: 768px) {
    .nav-items,
    .actions {
      display: none;
    }

    // .hamburger {
    //   display: inline;
    //   color: #ffffff;
    //   margin-left: auto;
    // }
  }

  // @media only screen and (min-width: 600px) {
  //   .nav-items,
  //   .actions {
  //     display: none;
  //   }
  // }
}

.ant-drawer-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  color: #102250;
  // background: #333;
}

.ant-drawer-close {
  color: #fffcfc73 !important;
}

.ant-drawer-title {
  color: #acacac !important;
  font-size: 18px !important;
}

.ant-drawer-header {
  color: #fff !important;
  background: linear-gradient(
    95.72deg,
    #4e596f 0.6%,
    #242a38 96.54%
  ) !important;
  border-bottom: 1px solid #5a5a5a93 !important;
}

.ant-menu {
  background: #fff !important;
  color: $primaryText !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-menu > .ant-menu-item-divider {
  background-color: #4e4d4d !important;
}

.ant-modal-title {
  color: #fff !important;
  // text-transform: uppercase !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.2) !important;
  padding: 0 15px;
  font-size: 14px;
  background: #eee !important;
}

.client-tabbed-view .ant-tabs-tab-btn:focus {
  color: inherit;
}
// .ant-btn-link {
//   color: #7ebaf3 !important;
// }

// .ant-btn-primary {
//   // background: linear-gradient(94.43deg, #f54b64 0%, #ff6b81 100%) !important;
//   // box-shadow: 0px 71px 127px rgba(245, 75, 100, 0.15),
//   //   0px 35.9437px 55.3641px rgba(245, 75, 100, 0.10125),
//   //   0px 14.2px 20.6375px rgba(245, 75, 100, 0.075),
//   //   0px 3.10625px 7.34219px rgba(245, 75, 100, 0.04875);

//   background: #f54b64 !important;
//   border-color: #ff6b81 !important;
// }

// .ant-btn {
//   border-radius: 4px !important;
//   color: $secondaryText !important;
//   background-color: $secondary !important;
// }

.ant-switch-checked {
  background-color: #f54b64 !important;
}

.primaryBtn {
  color: #ffffff !important;
  background-color: $primary;
  border-color: $primary;
  background: linear-gradient(94.43deg, #f54b64 0%, #ff6b81 100%);
  border-radius: 6px;
}

.primaryBtn a {
  color: #ffffff !important;
}

.primaryBtn:hover {
  color: $primaryForeground !important;
  background-color: $primaryBackgroundHover !important;
}

.read-only {
  pointer-events: none;
}

.dragUpload {
  .ant-upload-btn {
    width: 100%;
    display: block;
  }
}
.todayTab {
  .fc-view-container {
    overflow-x: scroll;
    > .fc-view {
      width: 1000px;
    }
  }
}
