.steps-content {
  padding: 10px;
}

.steps-action {
  margin-top: 24px;
}

.image-container {
  width: 100%;
  background-color: whitesmoke;
  padding: 5px;
  margin: 5px 0;
}

.image-container img {
  width: 100%;
}

.order {
  margin: 5px 0;
  height: 25px;
  width: 25px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: white;
  padding-top: 2px;
}

.remove-icon {
  color: red;
  font-size: 1.2rem;
  float: right;
  font-weight: bold;
  margin-top: 2px;
  position: relative;
  right: 5px;
}
