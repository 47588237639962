.photos-ready {
  width: 100%;
  background-color: #f0efef;
  padding: 10px;
  font-size: 1.1rem;
  border-radius: 5px;
}

.ant-upload-list-picture-card-container {
  float: left;
  width: 135px;
  height: 104px;
  margin: 0 8px 8px 0;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 135px;
  height: 100px;
  margin: 0 8px 8px 0;
}

.emptyImg {
  font-size: 40px;
  position: relative;
  top: 40px;
}
.contentBody {
  position: relative;
  z-index: 2;
  background: white;
}
.class-wrap {
  position: relative;
  display: inline-block;
}
.close-btn {
  margin-top: 5px;
  line-height: 10px;
  float: right;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.class-wrap:hover .close-btn {
  opacity: 1;
}
.ant-upload-text {
  border-style: dashed;
  display: grid;
  padding-bottom: 4em;
  padding: 3em 3em 3em 3em;
  border-color: lightgrey;
  max-height: 140px;
}
.ant-upload-text-device {
  border-style: dashed;
  display: grid;
  padding: 1em;
  border-color: lightgrey;
  max-height: 140px;
}
.ant-upload {
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
}

.icon-style {
  margin-left: 7px;
  margin-bottom: 7px;
}

.overlay {
  pointer-events: none;
}

.showImage {
  max-height: 145px;
  position: relative;
  &:hover {
    .ant-btn {
      background: #fff;
      border-color: #ddd;
      .anticon {
        color: #000;
      }
    }
  }
  img {
    height: 145px;
    object-fit: contain;
    object-position: center;
  }
  .ant-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50% !important;
    max-width: 30px;
    max-height: 30px;
    min-width: unset;
    .anticon {
      color: #fff;
      font-size: 18px;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.imageCard {
  margin: 0px 10px;
  border: 1px solid rgb(236, 236, 236);
  padding: 10px;
}
