.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
  background: rgba(255, 255, 255, 0.2);
}

//.image-gallery-content .image-gallery-slide .image-gallery-image {
//  max-height: calc(60vh - 16px);
//}
.image-gallery-icon:hover {
  color: #38434f;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 35px;
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
  position: relative;
  z-index: 10;
  bottom: 45px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  opacity: 0.8;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #fcfdff;
  width: 100px;
  opacity: 1;
  transition: ease-in-out 0.4s;
}

.image-gallery-left-nav {
  padding: 50px 10px;
  top: 40%;
  left: -18% !important;
  transform: translateY(-50%);
}

.image-gallery-right-nav {
  padding: 50px 10px;
  top: 40%;
  left: 123% !important;
  transform: translateY(-50%);
}

// .originalHeight {
//   max-height: 70vh !important;
// }

.additional {
  position: relative;
  width: 100vw;
  background: rgba(56, 67, 79, 0.8);
  box-shadow: 0px 4px 30px rgba(58, 55, 74, 0.1);
  backdrop-filter: blur(10px);
}

.img-gallery-wrapper {
  min-height: 70vh;
}

@media only screen and (max-width: 768px) {
  .img-gallery-wrapper {
    min-height: 544px;
  }
}

@media screen and (orientation: portrait) {
  .img-gallery-wrapper {
    min-height: 544px;
  }
}
