.thumbnail-container {
  height: 20%;
}

.thumbnail-list {
  white-space: nowrap;
  text-align: center;
  margin-left: -45px;
}

.thumbnail-image {
  display: block;
  padding: 2px;
  margin: 5px;
  max-width: 120px;
  height: auto;
}

.thumbnail-list li {
  display: inline-block;
}

.footor {
  position: absolute;
  bottom: 20px;
  padding: 20px;
}
