tr.ant-descriptions-row {
  border-bottom: 1px soild #000000 !important;
}
th.text-format {
  text-transform: capitalize;
  font-weight: bold;
}
td.text-format {
  text-transform: capitalize;
}

.toggle-active {
  color: #1890ff;
}
.map-view .ant-card-body {
  padding: 10px;
}
.map-view .order-list {
  max-height: 480px;
  overflow-y: auto;
}
.appointment-table {
  border: 1px solid #cccccc;
  width: 100%;
}
.appointment-table thead {
  background: #dadada;
  padding: 5px;
}
.appointment-table th,
.appointment-table td {
  padding: 5px;
  border: 1px thin #dadada !important;
  border-bottom: 1px solid #dadada !important;
}

tr.to-book {
  background: #ffff00;
}
tr.completed {
  background: #ffa39e;
}
tr.scheduled {
  background: #d3f261;
}
tr.rescheduled {
  background: #ffd591;
}

.out .ant-select-selector {
  border: 2px solid #8c8c8c !important;
}
.on-shoot .ant-select-selector {
  border: 2px solid #69c0ff !important;
}
.tentative .ant-select-selector {
  border: 2px solid #ff85c0 !important;
}
.re-schedule .ant-select-selector {
  border: 2px solid #d3adf7 !important;
}

.out,
tr.out {
  background: #8c8c8c;
  text-transform: uppercase;
}
.on-shoot,
tr.on-shoot {
  background: #69c0ff;
  text-transform: uppercase;
}
.tentative,
tr.tentative {
  background: #ff85c0;
  text-transform: uppercase;
}
.re-schedule,
tr.re-schedule {
  background: #d3adf7;
  text-transform: uppercase;
}

.orders-date-picker .ant-picker-input > input {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.tui-full-calendar-dayname.tui-full-calendar-today {
  text-align: center;
}

th.detail-cell {
  padding: 0px;
  width: 0px;
}
td.ant-descriptions-item-content.detail {
  padding: 10px 10px 0px 10px !important;
  width: 0px;
}
.detail-cell {
  display: inline-block;
  padding: 10px 24px;
  border: 1px solid #f0f0f0;
  margin: 10px 0px;
}
.detail .detail-cell {
  margin: 0px;
  min-width: 25%;
  height: 50px;
  border: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: none;
}

.calendar {
  height: 100%;
}
