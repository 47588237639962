.notes {
  .pinned-notes {
    background-color: #fbf4e4;
    margin-bottom: 20px;

    h5 {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-upload {
    font-size: 22px;
    border: none;
    border-radius: 5px;
    color: #1890ff;
  }

  .close-btn {
    position: absolute;
    bottom: 0;
    right: 0;

    button {
      opacity: 0.7;
      border: none;
    }
  }
}
