.form-group label {
  display: block;
  font-weight: 600;
}

.form-group {
  margin: 5px 0;
}

.form-container h3 {
  border-bottom: solid 2px #80b6ec;
  padding: 5px 0;
}

.timeline-container {
  /* max-height: 300px;
  overflow: auto; */
  padding: 20px 0;
  margin-bottom: 20px;
  /* -moz-box-shadow: inset 0 0 3px #cccccc;
  -webkit-box-shadow: inset 0 0 3px #cccccc;
  box-shadow: inset 0 0 3px #cccccc; */
}
.photog-detail {
  font-size: 14px;
  color: #333333;
  min-width: 300px;
}

.photog-detail a {
  color: #1079c6 !important;
  font-weight: bold;
}

.link-button {
  color: #1079c6;
  border: none;
}
